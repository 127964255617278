<div class="info-icons">
  <div class="info">
    <mat-icon>account_balance_wallet</mat-icon>
    <h4 class="mat-subheading-1">от {{ minOrderSum }} ₽</h4>
    <h4 class="mat-body-1" style="color: #7d7b7b">Минимальная доставка</h4>
  </div>

  <div class="info">
    <mat-icon>credit_card</mat-icon>
    <h4 class="mat-subheading-1">Оплата картой</h4>
    <h4 class="mat-body-1" style="color: #7d7b7b">У нас есть терминалы</h4>
  </div>

  <div class="info">
    <mat-icon>local_shipping</mat-icon>
    <h4 class="mat-subheading-1">1 час 20 минут</h4>
    <h4 class="mat-body-1" style="color: #7d7b7b">Среднее время доставки</h4>
  </div>
</div>
